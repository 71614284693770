var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("main", { staticClass: "boxed-layout" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn",
        on: {
          click: function ($event) {
            return _vm.$router.go(-1)
          },
        },
      },
      [
        _c("font-awesome-icon", { attrs: { icon: "chevron-left" } }),
        _vm._v("\n    Zurück\n  "),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "impressum" }, [
      _c("h1", [_vm._v("Impressum")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63\n      Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.\n    "
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("p", [_vm._v("Check den Paul GmbH")]),
      _vm._v(" "),
      _c("p", [_vm._v("CEO: Paul Paukowitsch")]),
      _vm._v(" "),
      _c("p", [_vm._v("Betriebsgebiet Nord 6,")]),
      _vm._v(" "),
      _c("p", [_vm._v("3385 Markersdorf,")]),
      _vm._v(" "),
      _c("p", [_vm._v("Österreich")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      Unternehmensgegenstand: Handelsgewerbe mit Ausnahme des reglementierten Handelsgewerbe und\n      Handelsagent\n    "
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("p", [_vm._v("Tel.: +43664 80 880 10 303")]),
      _vm._v(" "),
      _c("p", [_vm._v("E-Mail: office@checkdenpaul.at")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("p", [_vm._v("Mitglied bei: WKO")]),
      _vm._v(" "),
      _c("p", [_vm._v("Berufsrecht: Gewerbeordnung: www.ris.bka.gv.at")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Aufsichtsbehörde/Gewerbebehörde: Bezirkshauptmannschaft St.Pölten"
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      Berufsbezeichnung: Handelsgewerbe mit Ausnahme des reglementierten Handelsgewerbe und\n      Handelsagent\n    "
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("p", [_vm._v("Kontaktdaten des Verantwortlichen für Datenschutz")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die Kontaktdaten der Check\n      den Paul GmbH\n    "
        ),
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("Betriebsgebiet Nord 6, 3385 Markersdorf, Österreich")]),
      _vm._v(" "),
      _c("p", [_vm._v("Vertretungsberechtigt: Paul Paukowitsch")]),
      _vm._v(" "),
      _c("p", [_vm._v("E-Mail-Adresse: office@checkdenpaul.at")]),
      _vm._v(" "),
      _c("p", [_vm._v("Telefon: +43664 80 880 10 303")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }